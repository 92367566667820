import { createAuth0 } from '@auth0/auth0-vue'

const auth0 = createAuth0(
    {
        domain: window.env.AUTH0_DOMAIN,
        clientId: window.env.AUTH0_CLIENT_ID,
        authorizationParams: {
            redirect_uri: window.location.origin,
            audience: window.env.AUTH0_API_AUDIENCE,
            max_age: 604800, // 1 week
        },
        cacheLocation: 'localstorage',
    },
    {
        // https://auth0.github.io/auth0-vue/interfaces/Auth0PluginOptions.html
        // This is required to handle errors from Auth0 in the guard
        skipRedirectCallback: window.location.search.includes('error='),
        errorPath: '/sign-in-error',
    }
)

export default auth0
