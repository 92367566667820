import type { ConfirmationOptions } from 'primevue/confirmationoptions'
import { useConfirm } from 'primevue/useconfirm'

export interface ConfirmationOptionsExtended extends ConfirmationOptions {
    type?: 'warn' | 'confirm' // red or gray accept button color
}

export const useDialog = () => {
    const confirm = useConfirm()
    return {
        asyncRequire(options: ConfirmationOptionsExtended): Promise<boolean> {
            return new Promise((resolve) => {
                confirm.require({ ...options, accept: () => resolve(true), reject: () => resolve(false) })
            })
        },
        require(options: ConfirmationOptionsExtended): void {
            return confirm.require(options)
        },
    }
}
