<script setup lang="ts">
import { computed } from 'vue'
import DActionMenu, { type DMenuItem } from '@/components/DActionMenu.vue'
import type { Person } from '@prisma/client'
import { useCommsMenu } from '@/composables/commsMenu'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const props = defineProps<{
    people: Pick<Person, 'id' | 'email'>[]
    /**
     *  The entity label shall be used to set the subject of the email.
     */
    subject?: string
}>()

const { sendEmails, copyEmails } = useCommsMenu({ subject: props.subject })

const disabled = computed(() => !props.people?.length)

const menuItems = computed<DMenuItem[]>(() => [
    {
        label: t('components.CommsMenu.sendEmail', props.people?.length),
        icon: 'mail-share',
        command: () => sendEmails(props.people),
        disabled: disabled.value,
    },
    {
        label: t('components.CommsMenu.copyEmail', props.people?.length),
        icon: 'mail',
        command: () => copyEmails(props.people),
        disabled: disabled.value,
    },
])
</script>

<template>
    <div>
        <DActionMenu :menu-items="menuItems" icon="dots-vertical" type="ghost" />
    </div>
</template>
