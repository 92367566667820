<script lang="ts" setup generic="">
import { ref } from 'vue'
import { type SidebarPassThroughOptions } from 'primevue/sidebar'
import type { PassThrough } from 'primevue/ts-helpers'
import BaseIcon from '@/components/BaseIcon.vue'

const isVisible = ref(false)

const toggleHelpkitWidget = () => {
    isVisible.value = !isVisible.value
}

const sidebarPT: PassThrough<SidebarPassThroughOptions> = {
    root: ({ props }) => ({
        class: [
            'z-sidebar w-[39rem]',

            // Flexbox
            'flex',
            'flex-col',

            // Position
            'relative',
            {
                '!transition-none !transform-none !w-screen !h-screen !max-h-full !top-0 !left-0':
                    props.position == 'full',
            },

            // Size
            {
                'h-full w-80': props.position == 'left' || props.position == 'right',
                'h-auto w-full': props.position == 'top' || props.position == 'bottom',
            },

            // Shape
            'border-0 dark:border',
            'shadow-lg',

            // Colors
            'bg-surface-0 dark:bg-surface-800',
            'text-surface-700 dark:text-white/80',
            'dark:border-surface-700',

            // Transitions
            'transition-transform',
            'duration-300',

            // Misc
            'pointer-events-auto',
        ],
    }),
    header: {
        class: [
            // Flexbox and Alignment
            'flex items-center justify-between',
            'shrink-0',

            // Spacing - adjusted to make it 64px - and align with the helpkit header
            'p-4',

            // Colors
            'bg-white',
            'text-slate-700',
        ],
    },
    title: {
        class: ['font-bold text-lg'],
    },
    icons: {
        class: ['flex items-center'],
    },
    closeButton: {
        class: [
            'relative',

            // Flexbox and Alignment
            'flex items-center justify-center',

            // Size and Spacing
            'mr-2',
            'last:mr-0',
            'w-8 h-8',

            // Shape
            'border-0',
            'rounded-full',

            // Colors
            'text-slate-500',
            'bg-transparent',

            // Transitions
            'transition duration-200 ease-in-out',

            // States
            'hover:text-slate-700',
            'hover:bg-slate-100',
            'focus:border-none focus:border-offset-0 focus:ring focus:ring-inset',
            'focus:ring-primary-400/50 dark:focus:ring-primary-300/50',

            // Misc
            'overflow-hidden',
        ],
    },
    closeButtonIcon: {
        class: [
            // Display
            'inline-block',

            // Size
            'w-4',
            'h-4',
        ],
    },
    content: {
        class: [
            // Spacing and Size
            'p-0',
            'h-full',
            'w-full',

            // Growth and Overflow
            'grow',
            'overflow-y-hidden',
        ],
    },
    mask: ({ props }) => ({
        class: [
            // Transitions
            'transition-all',
            'duration-300',

            // Background and Effects
            {
                'has-[.mask-active]:bg-transparent bg-black/40': props.modal,
                'has-[.mask-active]:backdrop-blur-none backdrop-blur-sm': props.modal,
            },
        ],
    }),
    transition: ({ props }) => {
        return props.position === 'top'
            ? {
                  enterFromClass: 'translate-x-0 -translate-y-full translate-z-0 mask-active',
                  leaveToClass: 'translate-x-0 -translate-y-full translate-z-0 mask-active',
              }
            : props.position === 'bottom'
              ? {
                    enterFromClass: 'translate-x-0 translate-y-full translate-z-0 mask-active',
                    leaveToClass: 'translate-x-0 translate-y-full translate-z-0 mask-active',
                }
              : props.position === 'left'
                ? {
                      enterFromClass: '-translate-x-full translate-y-0 translate-z-0 mask-active',
                      leaveToClass: '-translate-x-full translate-y-0 translate-z-0 mask-active',
                  }
                : props.position === 'right'
                  ? {
                        enterFromClass: 'translate-x-full translate-y-0 translate-z-0 mask-active',
                        leaveToClass: 'translate-x-full translate-y-0 translate-z-0 mask-active',
                    }
                  : {
                        enterFromClass: 'opacity-0 mask-active',
                        enterActiveClass: 'transition-opacity duration-400 ease-in',
                        leaveActiveClass: 'transition-opacity duration-400 ease-in',
                        leaveToClass: 'opacity-0 mask-active',
                    }
    },
}
</script>
<template>
    <PSidebar id="helpkit-popover-frame" v-model:visible="isVisible" :pt="sidebarPT" position="right">
        <iframe src="https://academy.delight.sh/?widget=true&ai=true" class="relative h-screen w-full" />
    </PSidebar>

    <div id="lumo-button" class="">
        <a
            rel="noopener noreferrer"
            class="flex items-center gap-x-1.5 rounded-sm text-slate-700 hover:cursor-pointer hover:bg-slate-100 border border-4 border-white hover:border-slate-100"
            @click="toggleHelpkitWidget"
        >
            <BaseIcon icon="sparkles" class="text-slate-500" />
            <span class="text-small-medium">{{ $t('helpkit.buttonLabel') }}</span>
        </a>
    </div>
</template>
