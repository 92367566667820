<script lang="ts" setup>
import { computed } from 'vue'
import { useRouter, type RouteRecordRaw } from 'vue-router'
import type { FeatureToggle } from '@mindfuel/server/src/common/config'
import DButton from './DButton.vue'
import MarkerButton from './MarkerButton.vue'
import { useRoute } from 'vue-router'

const props = defineProps<{
    userRoles: ('admin' | 'owner')[]
    featureToggles: FeatureToggle[]
    route: string
    title?: string
}>()

const router = useRouter()
const routeRecord = useRoute()
const back = router.options.history.state.back
const backLink = isString(back) && !back.startsWith(props.route) ? back : '/'
const currentRoute = computed(() => router.getRoutes().find((route) => route.path === props.route)?.children || [])
const isActiveRoute = (item: RouteRecordRaw) => routeRecord.name?.toString().includes(item.name?.toString() || '')

function isString(value: unknown): value is string {
    return typeof value === 'string'
}

function shouldShow(route: RouteRecordRaw) {
    const roles = route.meta?.roles || []
    const featureToggles = route.meta?.featureToggles || []
    const hidden = route.meta?.hidden
    const hasAllRoles = roles.every((role) => props.userRoles.includes(role))
    const hasAllFeatureToggles = featureToggles.every((ft) => props.featureToggles.includes(ft))
    return hasAllRoles && hasAllFeatureToggles && !hidden
}
</script>

<template>
    <div class="h-full w-[256px] border border-slate-200 bg-white bottom-page-padding overflow-x-hidden">
        <div class="flex flex-col py-3 pl-3 pr-2">
            <div class="flex flex-col space-y-5">
                <div class="text-slate-700 pl-1">
                    <RouterLink :to="backLink">
                        <DButton type="ghost" icon="arrow-narrow-left" size="lg" class="-ml-1.5" />
                    </RouterLink>
                    <div>{{ title }}</div>
                </div>
                <section
                    v-for="section in currentRoute.filter(shouldShow)"
                    :key="section.path"
                    class="flex flex-col space-y-1"
                >
                    <template v-if="section.children?.length">
                        <h3 class="text-xs font-semibold text-slate-400 pl-1 mb-1">
                            {{ $t(section.name?.toString() + '.label') }}
                        </h3>
                        <RouterLink
                            v-for="item in section.children?.filter(shouldShow)"
                            :key="item.path"
                            :to="{ name: item.name }"
                            :data-testid="item.name"
                            class="mb-3 rounded hover:cursor-pointer hover:bg-slate-100 border border-4 hover:border-slate-100"
                            :class="[isActiveRoute(item) ? 'bg-slate-200 border-slate-200' : 'border-white']"
                        >
                            <span class="text-sm text-slate-700">{{ $t(item.name?.toString() || '') }}</span>
                        </RouterLink>
                    </template>
                    <template v-else>
                        <RouterLink
                            :key="section.path"
                            :to="{ name: section.name }"
                            :data-testid="section.name"
                            class="rounded hover:cursor-pointer hover:bg-slate-100 border border-4 border-white hover:border-slate-100"
                            :class="[isActiveRoute(section) ? 'bg-slate-200 border-slate-200' : 'border-white']"
                        >
                            <span class="text-sm text-slate-700">
                                {{ $t(section.name?.toString() + '.label' || '') }}
                            </span>
                        </RouterLink>
                    </template>
                </section>
            </div>
        </div>
        <slot name="bottom">
            <MarkerButton />
        </slot>
    </div>
</template>
