import type { App } from 'vue'
import PrimeVue, { type PrimeVueConfiguration } from 'primevue/config'
import Dropdown from 'primevue/dropdown'
import Button from 'primevue/button'
import Sidebar from 'primevue/sidebar'
import TabMenu from 'primevue/tabmenu'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import Menu from 'primevue/menu'
import TieredMenu from 'primevue/tieredmenu'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Chip from 'primevue/chip'
import InputText from 'primevue/inputtext'
import InputNumber from 'primevue/inputnumber'
import Checkbox from 'primevue/checkbox'
import OverlayPanel from 'primevue/overlaypanel'
import Listbox from 'primevue/listbox'
import ProgressSpinner from 'primevue/progressspinner'
import Toast from 'primevue/toast'
import ToastService from 'primevue/toastservice'
import TextArea from 'primevue/textarea'
import AutoComplete from 'primevue/autocomplete'
import Avatar from 'primevue/avatar'
import AvatarGroup from 'primevue/avatargroup'
import Slider from 'primevue/slider'
import InlineMessage from 'primevue/inlinemessage'
import Message from 'primevue/message'
import InputSwitch from 'primevue/inputswitch'
import MultiSelect from 'primevue/multiselect'
import ProgressBar from 'primevue/progressbar'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import ConfirmDialog from 'primevue/confirmdialog'
import ConfirmationService from 'primevue/confirmationservice'
import Dialog from 'primevue/dialog'
import Tree from 'primevue/tree'
import TreeSelect from 'primevue/treeselect'
import Skeleton from 'primevue/skeleton'
import Timeline from 'primevue/timeline'
import SelectButton from 'primevue/selectbutton'
import Tag from 'primevue/tag'
import Calendar from 'primevue/calendar'
import RadioButton from 'primevue/radiobutton'
import Panel from 'primevue/panel'
import VirtualScroller from 'primevue/virtualscroller'
import Tooltip, { type TooltipDirectivePassThroughOptions } from '@/components/DTooltipDirective'

const tooltipPassThrough: TooltipDirectivePassThroughOptions = {
    root: ({ context }) => ({
        class: [
            'max-w-[420px] shadow m-0 p-0 !z-tooltip rounded',
            {
                'ml-2': context?.right || (!context?.right && !context?.left && !context?.top && !context?.bottom),
                '-ml-2': context?.left,
                '-mt-2': context?.top,
                'mt-2': context?.bottom,
            },
        ],
    }),
    text: {
        class: 'text-xsmall break-words rounded bg-slate-100 p-1 text-slate-700',
    },
    arrow: ({ context }) => ({
        class: [
            'border-transparent border-solid',
            {
                '-ml-1 border-y-[0.25rem] border-r-[0.25rem] border-l-0 border-r-slate-100':
                    context?.right || (!context?.right && !context?.left && !context?.top && !context?.bottom),
                '-mr-1 border-y-[0.25rem] border-l-[0.25rem] border-r-0 border-l-slate-100': context?.left,
                '-mb-1 border-x-[0.25rem] border-t-[0.25rem] border-b-0 border-t-slate-100': context?.top,
                '-mt-1 border-x-[0.25rem] border-b-[0.25rem] border-t-0 border-b-slate-100': context?.bottom,
            },
        ],
    }),
}

export const primevueConfig: PrimeVueConfiguration = {
    pt: {
        directives: {
            dtooltip: tooltipPassThrough,
        },
    },
}

export default {
    install(app: App) {
        app.use(PrimeVue, primevueConfig)
        app.use(ToastService)
        app.use(ConfirmationService)
        app.directive('tooltip', Tooltip)
        app.component('PButton', Button)
        app.component('PDropdown', Dropdown)
        app.component('PSidebar', Sidebar)
        app.component('PTabMenu', TabMenu)
        app.component('PSlider', Slider)
        app.component('PInlineMessage', InlineMessage)
        app.component('PMenu', Menu)
        app.component('PTieredMenu', TieredMenu)
        app.component('PDataTable', DataTable)
        app.component('PColumn', Column)
        app.component('PChip', Chip)
        app.component('PInputText', InputText)
        app.component('PInputNumber', InputNumber)
        app.component('PCheckbox', Checkbox)
        app.component('PTabView', TabView)
        app.component('PTabPanel', TabPanel)
        app.component('POverlayPanel', OverlayPanel)
        app.component('PPanel', Panel)
        app.component('PListbox', Listbox)
        app.component('PProgressSpinner', ProgressSpinner)
        app.component('PToast', Toast)
        app.component('PTextArea', TextArea)
        app.component('PAutoComplete', AutoComplete)
        app.component('PAvatar', Avatar)
        app.component('PAvatarGroup', AvatarGroup)
        app.component('PMessage', Message)
        app.component('PInputSwitch', InputSwitch)
        app.component('PMultiSelect', MultiSelect)
        app.component('PProgressBar', ProgressBar)
        app.component('PAccordion', Accordion)
        app.component('PAccordionTab', AccordionTab)
        app.component('PConfirmDialog', ConfirmDialog)
        app.component('PDialog', Dialog)
        app.component('PTree', Tree)
        app.component('PTreeSelect', TreeSelect)
        app.component('PSkeleton', Skeleton)
        app.component('PTimeline', Timeline)
        app.component('PSelectButton', SelectButton)
        app.component('PTag', Tag)
        app.component('PCalendar', Calendar)
        app.component('PRadioButton', RadioButton)
        app.component('PVirtualScroller', VirtualScroller)
    },
}

declare module '@vue/runtime-core' {
    export interface GlobalComponents {
        PButton: typeof Button
        PDropdown: typeof Dropdown
        PSidebar: typeof Sidebar
        PTabMenu: typeof TabMenu
        PSlider: typeof Slider
        PInlineMessage: typeof InlineMessage
        PMenu: typeof Menu
        PTieredMenu: typeof TieredMenu
        PDataTable: typeof DataTable
        PColumn: typeof Column
        PChip: typeof Chip
        PInputText: typeof InputText
        PInputNumber: typeof InputNumber
        PCheckbox: typeof Checkbox
        PTabView: typeof TabView
        PTabPanel: typeof TabPanel
        POverlayPanel: typeof OverlayPanel
        PPanel: typeof Panel
        PListbox: typeof Listbox
        PProgressSpinner: typeof ProgressSpinner
        PToast: typeof Toast
        PTextArea: typeof TextArea
        PAutoComplete: typeof AutoComplete
        PAvatar: typeof Avatar
        PAvatarGroup: typeof AvatarGroup
        PMessage: typeof Message
        PInputSwitch: typeof InputSwitch
        PMultiSelect: typeof MultiSelect
        PProgressBar: typeof ProgressBar
        PAccordion: typeof Accordion
        PAccordionTab: typeof AccordionTab
        PConfirmDialog: typeof ConfirmDialog
        PDialog: typeof Dialog
        PTree: typeof Tree
        PTreeSelect: typeof TreeSelect
        PSkeleton: typeof Skeleton
        PTimeline: typeof Timeline
        PSelectButton: typeof SelectButton
        PTag: typeof Tag
        PCalendar: typeof Calendar
        PRadioButton: typeof RadioButton
        PVirtualScroller: typeof VirtualScroller
    }
}
