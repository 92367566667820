import { format, intlFormatDistance } from 'date-fns'

export const useFormatTimeAgo = (date: Date | undefined) => {
    if (!date) return
    return intlFormatDistance(date, new Date(), { locale: 'en' })
}

/**
 * Formats the date like in the design:  '13 April 2024'
 */
export const formatDateLocale = (date: Date) => {
    return date.toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
    })
}

export const formatDateTime = (date: Date) => {
    return format(date, 'yyyy-MM-dd HH:mm:ss')
}
